import * as Yup from 'yup'

export const LoginSchema = Yup.object().shape({
    identifier: Yup.string()
        .required('errors.incorrectIdentifier')
        .test('checkPhoneOrMail', 'errors.incorrectIdentifier', function (value) {
            const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
            const phoneRegex = /^[+][0-9]{2}[0-9]{10}$/
            const phone2Regex = /^[[0-9]{10}$/
            const isValidEmail = emailRegex.test(value)
            const isValidPhone = phoneRegex.test(value) || phone2Regex.test(value)
            if (!isValidEmail && !isValidPhone) {
                return false
            }
            return true
        }),
})

export const SignUpPhoneRequiredSchema = Yup.object().shape({
    name: Yup.string().required('errors.missingName'),
    identifier: Yup.string()
        .required('errors.incorrectMail')
        .test('checkPhoneOrMail', 'errors.incorrectMail', function (value) {
            const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
            const phoneRegex = /^[+][0-9]{2}[0-9]{10}$/
            const isValidEmail = emailRegex.test(value)
            const isValidPhone = phoneRegex.test(value)
            if (!isValidEmail && !isValidPhone) {
                return false
            }
            return true
        }),
    phone: Yup.string()
        .required('errors.incorrectPhone')
        .test('checkPhone', 'errors.incorrectPhone', function (value) {
            const phoneRegex = /^[+][0-9]{2}[0-9]{10}$/
            const phone2Regex = /^[0-9]{10}$/
            const isValidPhone = phoneRegex.test(value) || phone2Regex.test(value)
            if (!isValidPhone) {
                return false
            }
            return true
        }),
})

export const PhoneSchema = Yup.object().shape({
    phone: Yup.string()
        .required('errors.incorrectIdentifier')
        .test('checkPhone', 'errors.incorrectPhone', function (value) {
            const phoneRegex = /^[+][0-9]{2}[0-9]{10}$/
            const phone2Regex = /^[0-9]{10}$/
            const isValidPhone = phoneRegex.test(value) || phone2Regex.test(value)
            if (!isValidPhone) {
                return false
            }
            return true
        }),
})

export const passwordSchema = Yup.object().shape({
    password: Yup.string().required('errors.passwordNotSet').min(6, 'errors.passwordShort'),
})

export const UserInfoSchema = Yup.object().shape({
    name: Yup.string().required().min(2, 'errors.nameShort').max(14, 'errors.nameLong'),
    identifier: Yup.string()
        .required('errors.incorrectIdentifier')
        .test('checkPhoneOrMail', 'errors.incorrectIdentifier', function (value) {
            const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
            const phoneRegex = /^[+][0-9]{2}[0-9]{10}$/
            const isValidEmail = emailRegex.test(value)
            const isValidPhone = phoneRegex.test(value)
            if (!isValidEmail && !isValidPhone) {
                return false
            }
            return true
        }),
})

export const OTPschema = Yup.object().shape({
    phoneOTPCode: Yup.string().required().length(6, 'il codice è lungo 6 caratteri'),
})

export const ConfirmPasswordSchema = Yup.object().shape({
    password: Yup.string().required('errors.passwordNotSet').min(6, 'errors.passwordShort'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'errors.passwordUnmatches'),
})
