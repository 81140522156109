import * as React from 'react'
import Image from 'next/image'

import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import SvgIcon from '@mui/material/SvgIcon'
import Box from '@mui/system/Box'

import TestyLogo from 'styles/icons/logo_testy.svg'

import Link from 'next/link'
import Routes from 'utils/routes'
import { useAppConfig } from 'components/hooks/features/use-features'

interface LogoProps {
    hideName?: boolean
    disableLink?: boolean
}

const Logo = (props: LogoProps): JSX.Element => {
    const { palette } = useTheme()
    const { appConfig } = useAppConfig()

    const appCode = process.env.NEXT_PUBLIC_APP_CODE

    const logoPath = React.useMemo(() => appConfig.image, [appConfig.image])
    const appName = React.useMemo(() => appConfig.name, [appConfig.name])

    if (!props.disableLink)
        return (
            <Link href={Routes.Home}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {appCode === 'testy' ? (
                        <SvgIcon component={TestyLogo} sx={{ width: 40, height: 40 }} viewBox="0 0 321 321" />
                    ) : (
                        <Image
                            src={logoPath}
                            alt={`${appCode} logo`}
                            style={{ borderRadius: 5 }}
                            width={40}
                            height={40}
                        />
                    )}
                    {props.hideName ? null : (
                        <Typography paddingLeft={1} color={palette.primary.main} variant="h2">
                            {appName}
                        </Typography>
                    )}
                </Box>
            </Link>
        )
    else
        return (
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {appCode === 'testy' ? (
                    <SvgIcon component={TestyLogo} sx={{ width: 40, height: 40 }} viewBox="0 0 321 321" />
                ) : (
                    <Image src={logoPath} alt={`${appCode} logo`} style={{ borderRadius: 5 }} width={40} height={40} />
                )}
                {props.hideName ? null : (
                    <Typography paddingLeft={1} color={palette.primary.main} variant="h2">
                        {appName}
                    </Typography>
                )}
            </Box>
        )
}

export default Logo
