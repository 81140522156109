import { User } from 'firebase/auth'
import { saveToStorage } from 'utils/local-storage'
import * as TestySDK from './testy-web-sdk'

interface Token {
    access_token: string
    access_token_expires_at: string
}

export const exchangeFirebaseTokenWithCustom = async (firebaseToken: string): Promise<Token> => {
    const token = await TestySDK.exchangeFirebaseToken(firebaseToken)
    saveToStorage('tokenExp', token.access_token_expires_at)
    saveToStorage('token', token.access_token)
    return token
}

export const userBasicDataParser = (user: User, token: Token) => {
    saveToStorage('tokenExp', token.access_token_expires_at)
    const out = {
        uid: user?.uid,
        logged: user !== undefined,
        email: user?.email,
        token: token.access_token,
    }
    return out
}
