import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import React from 'react'

interface PropsFormTextField extends OutlinedInputProps {
    onChangeFn: (arg0: string) => void
    helperText: string
    password?: boolean
}

const FormTextField = React.memo<PropsFormTextField>((props) => {
    const { onChangeFn, helperText, password, ...others } = props
    return (
        <FormControl fullWidth variant="outlined">
            <OutlinedInput
                label=""
                onChange={(event) => onChangeFn(event.target.value)}
                type={password ? 'password' : undefined}
                error={helperText !== ''}
                sx={{ my: 0.5 }}
                //style={{ width: '100%' }}
                //fullWidth
                size="small"
                {...others}
            />
            <FormHelperText error>{props.helperText}</FormHelperText>
        </FormControl>
    )
})

export default FormTextField
