import * as React from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'

import AppLogo from 'components/atomic/atoms/logo'

export default function AppLoader() {
    const { palette } = useTheme()
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <AppLogo hideName />
            <CircularProgress sx={{ color: palette.primary.main, marginTop: 5 }} size={30} />
        </div>
    )
}
