import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
//@ts-expect-error "no ts support"
import mixpanelPlugin from '@analytics/mixpanel'
import appConfig from 'utils/app.config.json'
import { AppEnvKey } from 'components/providers/app-config-provider'

const appCode = process.env.NEXT_PUBLIC_APP_CODE as AppEnvKey

//@ts-expect-error "fix that"
const appMeasurementId = appConfig[appCode].firebaseConfig.measurementId

const isBrowser = typeof window !== 'undefined'

export const analytics = Analytics({
    app: 'testy-webapp',
    plugins: isBrowser
        ? [
              googleAnalytics({
                  measurementIds: [appMeasurementId],
                  debug: process.env.NODE_ENV === 'production' ? false : true,
              }),
              mixpanelPlugin({
                  token: '6ca5a25ea776ea0917ee9c86b088e09e',
              }),
          ]
        : [],
})
