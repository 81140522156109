import { PaletteOptions } from '@mui/material'

export const defaultPalette: PaletteOptions = {
    primary: {
        main: '#5DC0AD',
        light: '#A9E0D4',
        dark: '#314D46',
        contrastText: '#4A887F',
    },
    error: {
        main: '#F08C8C',
        light: '#E7D4D4',
        dark: '#BA7171',
    },
    warning: {
        main: '#F0CC87',
        light: '#F2EBC4',
        dark: '#E8B758',
    },
    info: {
        main: '#8ABCFF',
        // light: '#8ABCFF',
        dark: '#327FE8',
    },
    success: {
        main: '#69C469',
        light: '#A1DEA1',
        dark: '#589758',
    },
    border: '#E2E2E2',
    font: {
        main: '#314D46',
        secondary: '#919C99',
        disabled: '#B1B8B6',
    },
}
