
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import * as React from 'react';
import { Analytics } from '@vercel/analytics/react';
import { SnackbarProvider } from 'notistack';
import type { AppProps } from 'next/app';
import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from 'components/providers/auth-provider';
import UserContextProvider from 'components/providers/context/user-context';
import PageProgress from 'components/atomic/atoms/page-progress';
import ThemeProvider from 'components/providers/theme-provider';
import { ModalProvider } from 'react-modal-hook';
import createEmotionCache from 'utils/theme';
/** Global styles */
import 'styles/globals.css';
import AppConfigProvider from 'components/providers/app-config-provider';
import { analytics } from 'utils/analytics';
interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache;
}
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
function App(props: MyAppProps) {
    const { emotionCache = clientSideEmotionCache, pageProps, Component, router } = props;
    const handleRouteChange = React.useCallback(() => {
        analytics.page();
    }, []);
    React.useEffect(() => {
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => router.events.off('routeChangeComplete', handleRouteChange);
    }, [handleRouteChange, router.events]);
    return (<CacheProvider value={emotionCache}>
            <AuthProvider>
                <AppConfigProvider>
                    <UserContextProvider>
                        <ThemeProvider>
                            <SnackbarProvider>
                                <ModalProvider>
                                    <CssBaseline />
                                    <PageProgress />
                                    <Component {...pageProps}/>
                                    <Analytics />
                                </ModalProvider>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </UserContextProvider>
                </AppConfigProvider>
            </AuthProvider>
        </CacheProvider>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  