import { Container, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import { AuthContext } from 'components/providers/auth-provider'
import { useFormik } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import * as React from 'react'
import * as TestySDK from 'utils/backend/sdk/testy-web-sdk'
import { PhoneSchema } from 'utils/form/validation-schema'
import Button from '../atoms/button'
import FormTextField from '../atoms/form-field-input'

const NoPhoneModal = () => {
    const { authData } = React.useContext(AuthContext)
    const { t } = useTranslation('common')
    const [done, setDone] = React.useState(true)

    const form = useFormik({
        initialValues: {
            phone: '',
        },
        validationSchema: PhoneSchema,
        onSubmit: ({ phone }) => {
            TestySDK.updateUser(authData?.userUid || '', undefined, phone).then(() => {
                setDone(false)
            })
        },
    })

    return (
        <Dialog open={done}>
            <Container maxWidth="xs" sx={{ p: 2, textAlign: 'center', width: '100vw' }}>
                <Typography variant="body1">Inserisci il tuo numero per usare il simulatore</Typography>
                <FormTextField
                    sx={{ mt: 2 }}
                    value={form.values.phone}
                    helperText={form.errors.phone ? t(`${form.errors.phone}`) : ''}
                    onChangeFn={form.handleChange('phone')}
                    placeholder="Inserisci il tuo numero di telefono"
                />
                <Button sx={{ my: 2 }} variant="contained" onClick={() => form.handleSubmit()} role="submit">
                    Salva numero
                </Button>
            </Container>
        </Dialog>
    )
}

export default NoPhoneModal
