import * as React from 'react'
import config from 'utils/app.config.json'

export type AppEnvKey = 'artquiz' | 'cma' | 'formamil' | 'goldman' | 'testy' | 'teachcorner' | 'studyforfun' | 'unimed'

interface AppProviderProps {
    children: JSX.Element | JSX.Element[]
}
interface ColorPalette {
    contrastText: string
    light: string
    main: string
    dark: string
}
interface FirebaseConfig {
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
}

const defaultConfig = config['testy']

interface AppContext {
    appConfig: {
        name: string
        feature: string[]
        image: string
        FirebaseConfig?: FirebaseConfig
        colorPalette?: ColorPalette
    }
}

export const AppContext = React.createContext<AppContext>({ appConfig: { ...defaultConfig } })

const AppConfigProvider = (props: AppProviderProps): JSX.Element => {
    const appCode = process.env.NEXT_PUBLIC_APP_CODE as AppEnvKey

    const clientConfig = React.useMemo(() => {
        if (appCode) {
            const clientFeature = config[appCode]
            return clientFeature
        } else return defaultConfig
    }, [appCode])

    return <AppContext.Provider value={{ appConfig: { ...clientConfig } }}>{props.children}</AppContext.Provider>
}

export default AppConfigProvider
