import * as React from 'react'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { defaultPalette } from 'styles/palettes/default-palette'
import { appTheme } from 'styles/theme'
import { useAppConfig } from 'components/hooks/features/use-features'
import { useAppData, useWorkspace } from 'components/hooks/swr'
import { UserContext } from '../context/user-context'
import { getCustomSetColors } from 'utils/colors/generated-colors'

interface CustomThemeProviderProps {
    children: JSX.Element | JSX.Element[]
}

const CustomThemeProvider = (props: CustomThemeProviderProps): JSX.Element => {
    const { appConfig } = useAppConfig()
    const { userData } = React.useContext(UserContext)
    const { data: appData } = useAppData()

    const { data: workspaceInfoData } = useWorkspace(userData?.appWorkspace || '')

    const customColorPalette = React.useMemo(() => {
        let clientPalette = appConfig.colorPalette

        if (clientPalette) {
            if (
                workspaceInfoData &&
                workspaceInfoData.theme.main_color !== '' &&
                !appData?.single_workspace &&
                defaultPalette.primary
            ) {
                const wsPalette = getCustomSetColors(workspaceInfoData.theme.main_color)

                clientPalette = wsPalette
            }

            defaultPalette.primary = clientPalette
            appTheme.typography.allVariants.color = defaultPalette.font.main
            appTheme.components.MuiButton.styleOverrides.text.color = defaultPalette.primary.main

            if (defaultPalette.primary.dark) {
                defaultPalette.font.main = defaultPalette.primary.dark
            }

            if (defaultPalette.primary.dark) {
                appTheme.components.MuiSvgIcon.styleOverrides.root.color = defaultPalette.primary.dark
            }

            return createTheme({ palette: defaultPalette, ...appTheme })
        }

        return createTheme({ palette: defaultPalette, ...appTheme })
    }, [appConfig.colorPalette, appData?.single_workspace, workspaceInfoData])

    return <ThemeProvider theme={customColorPalette}>{props.children}</ThemeProvider>
}

export default CustomThemeProvider
