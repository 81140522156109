/* eslint-disable radix */

export const getCustomSetColors = (mainColor: string) => {
    let r, g, b

    if (mainColor.startsWith('rgb')) {
        const rgbString = mainColor.substring(4, mainColor.length - 1)
        const rgbSplit = rgbString.split(',')
        r = parseInt(rgbSplit[0].trim(), 10)
        g = parseInt(rgbSplit[1].trim(), 10)
        b = parseInt(rgbSplit[2].trim(), 10)
    } else {
        const hexRgb = HEXtoRGB(mainColor)
        r = hexRgb.r
        g = hexRgb.g
        b = hexRgb.b
    }

    const hls = RGBtoHLS(r, g, b)

    return {
        main: mainColor,
        light: HSLToHEX(hls?.h || 0, hls?.l || 0, 80),
        dark: HSLToHEX(hls?.h || 0, hls?.l || 0, 15),
        contrastText: HSLToHEX(hls?.h || 0, hls?.l || 0, 30),
    }
}

const RGBtoHLS = (r: number, g: number, b: number): { h: number; l: number; s: number } | undefined => {
    r /= 255
    g /= 255
    b /= 255

    // Find greatest and smallest channel values
    const cmin = Math.min(r, g, b)
    const cmax = Math.max(r, g, b)
    const delta = cmax - cmin
    let h = 0,
        s = 0,
        l = 0

    if (delta === 0) h = 0
    // Red is max
    else if (cmax === r) h = ((g - b) / delta) % 6
    // Green is max
    else if (cmax === g) h = (b - r) / delta + 2
    // Blue is max
    else h = (r - g) / delta + 4

    h = Math.round(h * 60)

    // Make negative hues positive behind 360°
    if (h < 0) h += 360

    // Calculate lightness
    l = (cmax + cmin) / 2

    // Calculate saturation
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1)
    l = +(l * 100).toFixed(1)

    return { h, s, l }
}

const HEXtoRGB = (hex: string): { r: number; g: number; b: number } => {
    let r = 0,
        g = 0,
        b = 0

    // 3 digits
    if (hex.length === 4) {
        r = parseInt('0x' + hex[1] + hex[1])
        g = parseInt('0x' + hex[2] + hex[2])
        b = parseInt('0x' + hex[3] + hex[3])

        // 6 digits
    } else if (hex.length === 7) {
        r = parseInt('0x' + hex[1] + hex[2])
        g = parseInt('0x' + hex[3] + hex[4])
        b = parseInt('0x' + hex[5] + hex[6])
    }

    return { r, g, b }
}

const HSLToHEX = (h: number, s: number, l: number): string => {
    const hDecimal = l / 100
    const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100
    const f = (n: number): string => {
        const k = (n + h / 30) % 12
        const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)

        // Convert to Hex and prefix with "0" if required
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, '0')
    }
    return `#${f(0)}${f(8)}${f(4)}`
}

// const HSLToRGB = (h: number, s: number, l: number): string => {
//     s /= 100
//     l /= 100
//     const k = (n: number): number => (n + h / 30) % 12
//     const a = s * Math.min(l, 1 - l)
//     const f = (n: number): number => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)))
//     return `rgb(${255 * f(0)},${255 * f(8)},${255 * f(4)})`
// }
