export const saveToStorage = (key: string, value: any) => {
    if (typeof window !== 'undefined') {
        return window.localStorage.setItem(key, value !== undefined ? JSON.stringify(value) : 'null')
    }
}

export const getFromStorage = (key: string) => {
    if (typeof window !== 'undefined') {
        const res = window.localStorage.getItem(key)
        if (res !== null && res !== undefined && res !== 'undefined' && res !== 'null') {
            return JSON.parse(res)
        }
        return null
    }
}

export const clearStorage = () => {
    if (typeof window !== 'undefined') {
        window.localStorage.setItem('userId', 'null')
        window.localStorage.setItem('token', 'null')
        window.localStorage.setItem('savedWorkspace', 'null')
        window.localStorage.setItem('savedExam', 'null')
        window.localStorage.setItem('savedInstitutions', 'null')
        window.localStorage.setItem('tokenExp', 'null')
    }
}

export const saveLocalQuizOnlyRead = (quiz: Quiz) => {
    saveToStorage('quiz_read', quiz)
}

export const getLocalQuizOnlyRead = (): Quiz => {
    return getFromStorage('quiz_read')
}
