import * as React from 'react'

import { ButtonProps as MateriaButtonProps, Tooltip } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { styled } from '@mui/material/styles'

interface ButtonProps extends MateriaButtonProps {
    loading?: boolean
    tooltip?: string
}

const StyledButton = styled(LoadingButton)<ButtonProps>`
    text-transform: none;
    font-family: manrope;
`

const Button = ({ children, tooltip, ...rest }: ButtonProps) => {
    return tooltip ? (
        <Tooltip arrow title={tooltip}>
            <span>
                <StyledButton disableElevation {...rest}>
                    {children}
                </StyledButton>
            </span>
        </Tooltip>
    ) : (
        <StyledButton disableElevation {...rest}>
            {children}
        </StyledButton>
    )
}

export default Button
