const Routes = {
    Home: '/simulations/exercise/homepage',
    Login: '/login',
    SignUp: '/signup',
    Workspace: '/workspace',
    Onboarding: '/onboarding',
    QuizOverview: '/quiz/overview',
    QuizHome: '/quiz/exercise/homepage',
    CorrectionQuiz: '/quiz/correction',
    PageQuiz: '/quiz/quiz',
    NewQuiz: '/quiz/new',
    QuizStats: '/quiz/stats',
    StatsTopic: '/quiz/stats-topic',
    SimulationsOverview: '/simulations/overview',
    SimulationsCorrection: '/simulations/correction',
    SimulationsHome: '/simulations/exercise/homepage',
    SimulationsPage: '/simulations/simulation',
    NewSimulation: '/simulations/new',
    ChallengeHome: '/duels/competition/homepage',
    Challenge: '/duels/challenge',
    ChallengeCorrection: '/duels/correction',
    Settings: '/settings/exams-settings',
}

export const PublicRoutes = [Routes.Login, Routes.SignUp]
export const OnboardingRoutes = [Routes.Onboarding]
export const WsRoutes = [Routes.Workspace]

export default Routes
