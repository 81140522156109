import * as React from 'react'
import * as TestySdk from 'utils/backend/sdk/testy-web-sdk'

import router from 'next/router'
import { AuthContext } from 'components/providers/auth-provider'

import { useAppData, useUser, useUserWorkspaces } from 'components/hooks/swr'
import { getFromStorage, saveToStorage } from 'utils/local-storage'
import Routes, { OnboardingRoutes, PublicRoutes, WsRoutes } from 'utils/routes'

import AppLoader from 'components/atomic/molecules/app-loader'
import LockedPage from 'components/atomic/organism/locked-page'
import { useAppConfig } from 'components/hooks/features/use-features'
import NoPhoneModal from 'components/atomic/molecules/no-phone-modal'

interface UserContextProviderProps {
    children: JSX.Element
}

interface UserContext {
    userData?: UserData
    chooseExam?: (val: string, val2: string, val3: string) => void
    chooseWorkspace?: (val: string) => void
    chooseInstitutions?: (val: string) => void
    addWorkspace?: (val?: string, val2?: string) => Promise<JoinWorkspaceResponse | undefined>
}

export interface UserBasicData {
    uid: string
    logged: boolean
    email?: string | null
    token: string
}

export const UserContext = React.createContext<UserContext>({
    userData: {},
})

export default function UserContextProvider({ children }: UserContextProviderProps) {
    const { authData } = React.useContext(AuthContext)

    const [userWorkspace, setUserWorkspace] = React.useState<UserWorkspace>()
    const [defaultExam, setDefaultExam] = React.useState<ExamSummary>()
    const [noPhone, setNoPhone] = React.useState<boolean>()
    const isPublicRoutes = () => PublicRoutes.includes(router.pathname)
    const isOnboardingRoutes = () => OnboardingRoutes.includes(router.pathname)
    const isAddingWsRoutes = () => WsRoutes.includes(router.pathname)

    const { appConfig } = useAppConfig()

    const phoneRequired = appConfig.feature.includes('phoneRequired')

    const { data: appData } = useAppData()
    const {
        data: userWorkspacesData,
        loading: userWorkspacesLoading,
        mutate: userWorkspacesMutate,
    } = useUserWorkspaces()
    const { data: userBasicData } = useUser(phoneRequired && authData?.userUid ? authData?.userUid : '')

    const chooseInstitutions = (institutionsId: string) => {
        saveToStorage('savedInstitutions', institutionsId)
    }

    const getExam = React.useCallback(() => {
        const customExam = getFromStorage('savedExam')

        if (userWorkspace && authData?.userUid) {
            if (
                !userWorkspace.exams.length &&
                !isAddingWsRoutes() &&
                !isOnboardingRoutes() &&
                !appData?.single_workspace
            )
                router.push(Routes.Settings)
            if (
                !userWorkspace.exams.length &&
                !isAddingWsRoutes() &&
                !isOnboardingRoutes() &&
                appData?.single_workspace
            )
                router.push(Routes.Onboarding)
            if (customExam !== null) {
                let examSelected = userWorkspace?.exams.find((el) => {
                    if (el.exam?.id && el.exam.id === customExam) return el.exam.id
                })

                if (!examSelected) examSelected = userWorkspace?.exams.find((el) => el.default)

                saveToStorage('savedExam', examSelected?.exam.id)
                saveToStorage('savedInstitutions', examSelected?.exam.institution)
                setDefaultExam(examSelected?.exam)
            } else {
                const examSelected = userWorkspace?.exams.find((el) => el.default === true)

                saveToStorage('savedExam', examSelected?.exam.id)
                saveToStorage('savedInstitutions', examSelected?.exam.institution)
                setDefaultExam(examSelected?.exam)
            }
        }
    }, [appData?.single_workspace, authData?.userUid, userWorkspace])

    const checkWorkspace = React.useCallback(async () => {
        //create a workspace if userWorkspaceData is empty

        if (
            userWorkspacesData &&
            authData?.userUid &&
            !userWorkspacesData?.length &&
            !userWorkspacesLoading &&
            !isOnboardingRoutes() &&
            !isPublicRoutes() &&
            !isAddingWsRoutes()
        ) {
            router.push(Routes.Onboarding)
        }
    }, [authData?.userUid, userWorkspacesData, userWorkspacesLoading])

    const handleSetUserWorkspace = React.useCallback(() => {
        const ws = getFromStorage('savedWorkspace')

        if (!ws) {
            setUserWorkspace(
                userWorkspacesData?.find((w: { workspace: string }) =>
                    appData?.workspaces.includes(w.workspace),
                ) as UserWorkspace,
            )
            getExam()
        } else {
            setUserWorkspace(userWorkspacesData?.find((w) => w.workspace === ws))
            getExam()
        }
    }, [appData, getExam, userWorkspacesData])

    const chooseWorkspace = React.useCallback(
        (workspaceId: string) => {
            saveToStorage('savedWorkspace', workspaceId)
            handleSetUserWorkspace()
            userWorkspacesMutate()
            getExam()
        },
        [getExam, handleSetUserWorkspace, userWorkspacesMutate],
    )

    const addWorkspace = React.useCallback(
        async (workspaceId?: string, workspaceCode?: string): Promise<JoinWorkspaceResponse | undefined> => {
            saveToStorage('savedWorkspace', workspaceId)
            if (authData?.userUid) {
                try {
                    let res
                    if (workspaceCode) {
                        res = await TestySdk.createUserWorkspace(authData?.userUid, undefined, workspaceCode)
                        saveToStorage('savedWorkspace', res.workspace)
                        userWorkspacesMutate()
                    } else {
                        res = await TestySdk.createUserWorkspace(authData?.userUid, appData?.default_workspace)
                        userWorkspacesMutate()
                    }
                    handleSetUserWorkspace()
                    userWorkspacesMutate()
                    return res
                } catch (e) {
                    throw e
                }
            }
            return undefined
        },
        [appData?.default_workspace, authData?.userUid, handleSetUserWorkspace, userWorkspacesMutate],
    )

    const chooseExam = React.useCallback(
        (examId: string, schoolId: string) => {
            saveToStorage('savedExam', examId)
            saveToStorage('savedInstitutions', schoolId)

            if (authData?.userUid)
                TestySdk.updateUserWorkspace({
                    userId: authData?.userUid,
                    examId: examId,
                    institutionId: schoolId,
                    userWorkspaceId: userWorkspace?._id,
                })
                    .then(() => {
                        getExam()
                        userWorkspacesMutate()
                    })
                    .catch((e) => {
                        throw e
                    })
        },

        [authData, getExam, userWorkspace?._id, userWorkspacesMutate],
    )

    React.useEffect(() => {
        //Join workspace if workspace is missing
        checkWorkspace()
        //getting user workspace info from appdata
        handleSetUserWorkspace()
    }, [checkWorkspace, handleSetUserWorkspace, chooseWorkspace, addWorkspace, userWorkspacesData, authData, appData])

    React.useEffect(() => {
        if (phoneRequired) {
            if (!userBasicData?.phone_number) setNoPhone(true)
            else setNoPhone(false)
        }
    }, [phoneRequired, userBasicData?.phone_number])

    //set the user info used in app.
    const userData = React.useMemo(() => {
        return {
            institutionId: defaultExam?.institution,
            appWorkspace: userWorkspace?.workspace,
            workspaceId: userWorkspace?._id,
            examId: defaultExam?.id,
        }
    }, [defaultExam?.id, defaultExam?.institution, userWorkspace?._id, userWorkspace?.workspace])

    if (
        !isPublicRoutes() &&
        !!userWorkspace?.subscription.plan &&
        !!userWorkspace?.subscription.plan.web_app_access &&
        userWorkspace?.subscription.plan.web_app_access !== undefined &&
        !userWorkspace?.subscription.plan.web_app_access
    )
        return <LockedPage />

    if (!isPublicRoutes() && !isOnboardingRoutes() && !userData?.appWorkspace && !userData.examId) return <AppLoader />

    return (
        <UserContext.Provider value={{ userData, chooseExam, chooseWorkspace, chooseInstitutions, addWorkspace }}>
            {noPhone && !isPublicRoutes() && !isOnboardingRoutes() && authData?.userUid ? <NoPhoneModal /> : null}
            {children}
        </UserContext.Provider>
    )
}
