import { AppEnvKey } from 'components/providers/app-config-provider'
import { FirebaseError, initializeApp } from 'firebase/app'
import * as FirebaseAuth from 'firebase/auth'
import * as TestySDK from 'utils/backend/sdk/testy-web-sdk'
import * as LocalStorageUtils from 'utils/local-storage'
import appConfig from 'utils/app.config.json'
import { saveToStorage } from 'utils/local-storage'
import { exchangeFirebaseTokenWithCustom } from './auth'
import router from 'next/router'
import Routes from 'utils/routes'
import { ApplicationVerifier, ConfirmationResult, sendPasswordResetEmail } from 'firebase/auth'

const appCode = process.env.NEXT_PUBLIC_APP_CODE as AppEnvKey
const firebaseConfig = appConfig[appCode].firebaseConfig
const firebaseApp = initializeApp(firebaseConfig)

const auth = FirebaseAuth.getAuth(firebaseApp)
auth.languageCode = 'it'

const mapFirebaseError = (error: FirebaseError) => {
    switch (error.code) {
        case 'auth/invalid-verification-code': {
            return new Error('Codice di verifica non valido')
            break
        }
        case 'auth/invalid-verification-id': {
            return new Error('ID di verifica non valido')
            break
        }
        case 'auth/missing-verification-code': {
            return new Error('Codice di verifica mancante')
            break
        }
        case 'auth/missing-verification-id': {
            return new Error('ID di verifica mancante')
            break
        }
        case 'auth/credential-already-in-use': {
            return new Error('Credenziali già utilizzate')
            break
        }
        case 'auth/credential-mismatch': {
            return new Error('Credenziali sbagliate')
            break
        }
        case 'auth/email-already-in-use': {
            return new Error('Email già utilizzata')
            break
        }
        case 'auth/invalid-email': {
            return new Error('Email non valida')
            break
        }
        case 'auth/invalid-password': {
            return new Error('Password non valida')
            break
        }
        case 'auth/user-deleted': {
            return new Error('Utente eliminato')
            break
        }
        case 'auth/user-disabled': {
            return new Error('Utente disabilitato')
            break
        }
        case 'auth/user-not-found': {
            return new Error('Utente non trovato')
            break
        }
        case 'auth/weak-password': {
            return new Error('Password debole')
            break
        }
        case 'auth/too-many-requests': {
            return new Error('Troppi tentativi, riprovare dopo')
            break
        }
        case 'auth/unverified-email': {
            return new Error('Email non verificata')
            break
        }
        default: {
            return new Error('Si è verificato un errore, riprovare')
        }
    }
}

export const signInWithEmailAndPassword = async (email: string, password: string) => {
    try {
        await auth.setPersistence(FirebaseAuth.browserLocalPersistence)
        const { user } = await FirebaseAuth.signInWithEmailAndPassword(auth, email, password)
        return user
    } catch (error) {
        if (error instanceof FirebaseError) throw mapFirebaseError(error)
        else throw new Error("Non è stato possibile effettuare l'accesso, riprovare dopo")
    }
}

export const signUpWithEmailAndPassword = async (email: string, password: string) => {
    try {
        await auth.setPersistence(FirebaseAuth.browserLocalPersistence)
        const { user } = await FirebaseAuth.createUserWithEmailAndPassword(auth, email, password)
        return user
    } catch (error) {
        if (error instanceof FirebaseError) throw mapFirebaseError(error)
        else throw new Error("Non è stato possibile creare l'account, riprovare dopo")
    }
}

export const signInWithPhoneNumber = async (phoneNumber: string, OTPCode: ApplicationVerifier) => {
    try {
        await auth.setPersistence(FirebaseAuth.browserLocalPersistence)
        const user = await FirebaseAuth.signInWithPhoneNumber(auth, phoneNumber, OTPCode)
        return user
    } catch (e) {
        if (e instanceof FirebaseError) throw mapFirebaseError(e)
        else throw new Error("Non è stato possibile creare l'account, riprovare dopo")
    }
}

export const recoverPassword = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email)
    } catch (error) {
        if (error instanceof FirebaseError) throw mapFirebaseError(error)
        else throw new Error('Non è stato possibile recuperare la password. Contatta il supporto clienti')
    }
}

export const RefreshFbToken = () => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
        let token
        unsubscribe()
        if (user) {
            token = await user.getIdToken(true)
            exchangeFirebaseTokenWithCustom(token).then((newToken) => {
                saveToStorage('token', newToken.access_token)
                saveToStorage('tokenExp', newToken.access_token_expires_at)
                TestySDK.setAuthToken(newToken.access_token)
            })
        } else {
            await logout()
            LocalStorageUtils.clearStorage()
            router.push(Routes.Login)
            token = undefined
        }
        return token
    })
}

export const checkPhoneVerificationCode = async (
    verificationCode: string,
    confirmationResult: FirebaseAuth.ConfirmationResult,
) => {
    try {
        const credential = await confirmationResult.confirm(verificationCode)
        return credential
    } catch (error) {
        if (error instanceof FirebaseError) throw mapFirebaseError(error)
        else throw new Error('Non è stato possibile verificare il codice, riprovare dopo')
    }
}

export const signWithPhoneNumber = async (phone: string) => {
    try {
        // 'recaptcha-container' is the ID of an element in the DOM.
        //const applicationVerifier = new FirebaseAuth.RecaptchaVerifier('recaptcha-container', {}, auth)
        const applicationVerifier = new FirebaseAuth.RecaptchaVerifier(
            'recaptcha-container',
            {
                size: 'invisible',
                callback: async (response: ConfirmationResult) => {
                    return response
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                },
            },
            auth,
        )

        const confirmationResult = await FirebaseAuth.signInWithPhoneNumber(auth, phone, applicationVerifier)
        return confirmationResult
    } catch (error) {
        if (error instanceof FirebaseError) throw mapFirebaseError(error)
        else throw new Error('Non è stato possibile accedere con il numero di telefono, riprovare dopo')
    }
}

export const signWithGoogle = async () => {
    const provider = new FirebaseAuth.GoogleAuthProvider()
    auth.languageCode = 'it'

    try {
        const result = await FirebaseAuth.signInWithPopup(auth, provider)
        const fbToken = await result.user.getIdToken()
        return { result: result, token: fbToken }
    } catch (error) {
        if (error instanceof FirebaseError) throw mapFirebaseError(error)
        else throw new Error('Non è stato possibile accedere con google, riprovare dopo')
    }
}

export const logout = async () => {
    try {
        await FirebaseAuth.signOut(auth)
        const user = {}

        return user
    } catch (error) {
        if (error instanceof FirebaseError) throw mapFirebaseError(error)
        else throw new Error('Non è stato possibile loggare out,  riprovare dopo')
    }
}
