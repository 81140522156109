import '@fontsource/inter'
import '@fontsource/inter/700.css'
import '@fontsource/inter/500.css'
import '@fontsource/manrope'
import '@fontsource/manrope/600.css'
import '@fontsource/manrope/700.css'

interface FontColor {
    main: string
    secondary: string
    disabled: string
}

declare module '@mui/material/styles' {
    interface Palette {
        border: string
        font: FontColor
    }

    interface PaletteOptions {
        // mcyan: PaletteOptions['primary']
        // mgrey: PaletteOptions['primary']
        // mblack: PaletteOptions['primary']
        border: string
        font: FontColor
    }

    interface SizingOption {
        sizes: {
            xxs: number
            xs: number
            s: number
            m: number
            l: number
            xl: number
            xxl: number
            huge: number
            giant: number
        }
    }

    interface TypographyVariants {
        highlight_body1: React.CSSProperties
        highlight_body2: React.CSSProperties
        button_large: React.CSSProperties
        button_small: React.CSSProperties
        caption_highlight: React.CSSProperties
        helper_text: React.CSSProperties
        input_text: React.CSSProperties
        tooltip: React.CSSProperties
        input_label: React.CSSProperties
        nav_text: React.CSSProperties
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        highlight_body1?: React.CSSProperties
        highlight_body2?: React.CSSProperties
        button_large?: React.CSSProperties
        button_small?: React.CSSProperties
        caption_highlight?: React.CSSProperties
        helper_text?: React.CSSProperties
        input_text?: React.CSSProperties
        tooltip?: React.CSSProperties
        input_label?: React.CSSProperties
        nav_text?: React.CSSProperties
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        highlight_body1: true
        highlight_body2: true
        button_large: true
        button_small: true
        caption_highlight: true
        helper_text: true
        input_text: true
        tooltip: true
        input_label: true
        nav_text: true
    }
}

const button_large_text_style = {
    fontWeight: 700,
    fontSize: 14,
    letterSpacing: 0.4,
}

const button_small_text_style = {
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: 0.4,
}

const variantsTyopgraphy = {
    h1: {
        fontFamily: 'manrope',
        fontWeight: 700,
        fontSize: '2rem',
        letterSpacing: 0.25,
    },
    h2: {
        fontFamily: 'manrope',
        fontWeight: 700,
        fontSize: '1.5rem',
        letterSpacing: 0.25,
    },
    h3: {
        fontFamily: 'manrope',
        fontWeight: 700,
        fontSize: '1.2rem',
        letterSpacing: 0.15,
    },
    subtitle1: {
        fontWeight: 400,
        fontSize: '0.9rem',
        letterSpacing: 0.15,
    },
    subtitle2: {
        fontColor: '#919C99',
        fontWeight: 400,
        fontSize: '0.8rem',
        letterSpacing: 0.1,
    },
    body1: {
        fontWeight: 400,
        fontSize: '1rem',
        letterSpacing: 0.15,
    },
    body2: {
        fontWeight: 400,
        fontSize: '0.87rem',
        letterSpacing: 0.15,
    },
    highlight_body1: {
        fontFamily: 'manrope',
        fontWeight: 700,
        fontSize: '1.0rem',
        letterSpacing: 0.15,
    },
    highlight_body2: {
        fontFamily: 'manrope',
        fontWeight: 700,
        fontSize: '0.9rem',
        letterSpacing: 0.15,
    },
    button_large: button_large_text_style,
    button_small: button_small_text_style,
    caption: {
        fontWeight: 400,
        fontSize: '0.8rem',
        letterSpacing: 0.4,
    },
    caption_highlight: {
        fontWeight: 500,
        fontSize: '0.8rem',
        letterSpacing: 0.15,
    },
    helper_text: {
        fontWeight: 400,
        fontSize: '0.8rem',
        letterSpacing: 0.4,
    },
    input_text: {
        fontWeight: 400,
        fontSize: '1.2rem',
        letterSpacing: 0.4,
    },
    tooltip: {
        fontWeight: 400,
        fontSize: '0.6rem',
        letterSpacing: 0.15,
    },
    input_label: {
        fontWeight: 400,
        fontSize: '0.8rem',
        letterSpacing: 0.15,
    },
    nav_text: {
        fontWeight: 500,
        fontSize: '0.6rem',
        letterSpacing: 0.15,
    },
}

export const appTheme = {
    typography: {
        allVariants: {
            color: '#314D46',
            fontFamily: 'inter',
        },
        ...variantsTyopgraphy,
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle1: 'h6',
                    subtitle2: 'h6',
                    body1: 'p',
                    body2: 'p',
                    inherit: 'p',
                    highlight_body1: 'p',
                    highlight_body2: 'p',
                    helper_text: 'p',
                    input_label: 'p',
                    input_text: 'p',
                    button_large: 'p',
                    button_small: 'p',
                    caption: 'p',
                    caption_highlight: 'p',
                    tooltip: 'p',
                    nav_text: 'p',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#E6E6E6',
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                colorPrimary: '#FF0000',
            },
        },
        MuiIconButton: {
            styleOverrides: {
                colorPrimary: '#3A3A3A',
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#314D46',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                text: {
                    color: '#5DC0AD',
                    ...button_large_text_style,
                },
                contained: {
                    boxShadow: 'none',
                    color: 'white',
                    borderRadius: '6px',
                    ...button_large_text_style,
                },
                outlined: {
                    border: '1px solid',
                    borderRadius: '6px',
                    ...button_large_text_style,
                },
                /* text: {
                  fontWeight: 900,
                  fontSize: 14
                } */
            },
        },
    },
}
