import * as React from 'react'
import * as FirebaseUtils from 'utils/backend/sdk/firebase'
import * as LocalStorageUtils from 'utils/local-storage'

import { useTheme } from '@mui/material'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Button from 'components/atomic/atoms/button'
import { AuthContext } from 'components/providers/auth-provider'

const LockedPage = () => {
    const { palette } = useTheme()
    const { removeUser } = React.useContext(AuthContext)

    const logoutUser = async () => {
        await FirebaseUtils.logout()
        LocalStorageUtils.clearStorage()
        removeUser()
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'center',
                height: '100vh',
            }}>
            <Typography variant="h2">Mi spiace, ma non puoi accedere alla web app!😔</Typography>
            <Button variant="contained" onClick={() => logoutUser()}>
                Logout
            </Button>

            <Typography variant="body2" sx={{ color: palette.primary.main }}>
                Contatta il supporto clienti per essere abilitato.
            </Typography>
        </Box>
    )
}

export default LockedPage
